<template>
  <div class="fill-height">
    <v-fade-transition mode="out-in">

      <div class="centering text-center" :key="1" v-if="status == 'no_token'">
        <img src="../../assets/icons/faild.png" width="150px" style="margin: 0px auto" alt="">
        <br>
        <div class="mt-4">
          <b>
            لینک شما منقضی شده است
          </b>
        </div>
        <div class="mt-4">
          لطفا از طریق تلگرام پیگیری کنید
        </div>
        <br>
        <a href="https://t.me/simiaroom_cs">
          <v-btn class="mt-5 white--text" elevation="0" block color="blue">
            رفتن به تلگرام
          </v-btn>
        </a>
      </div>
      <AuthInitializeWindow :key="2" v-else-if="status == 'have_token'" @afterLogin="afterLogin" @afterCatch="afterCatch"/>
      <div :key="3" v-else-if="status == 'success'">
        <div class="centering text-center" >
          <img src="../../assets/icons/success.png" width="150px" style="margin: 0px auto" alt="">
          <br>
          <div class="mt-4">
            <b>
              اعتبار سنجی شما انجام شد
            </b>
          </div>
          <br>
          <a href="https://t.me/simiaroom_cs">
            <v-btn class="mt-5 white--text" elevation="0" block color="primary">
              بازگشت به تلگرام
            </v-btn>
          </a>
        </div>
      </div>

    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import AuthInitializeWindow from "@/components/Auth/AuthInitializeWindow";

export default {
  components: {AuthInitializeWindow},
  data() {
    return {
      status: null,
    }
  },
  methods: {
    afterLogin() {
      this.status = 'success'
    },
    afterCatch(){
      // this.status = 'no_token'
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.status = "have_token"
    }else{
      this.status = 'no_token'
    }
  }
}
</script>

<style scoped>
.centering {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  transform: translate(-50%, -50%);
}
</style>